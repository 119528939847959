import { defineStore } from 'pinia'
import { login, getProfile, updateUserDetails } from '@api/user'
import { removeToken, tokenStore } from '@api/accessToken'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import { isNonAuthRoute, isExternalRoute } from '@/util/routes'
import { ref, computed } from 'vue'
import type { User } from '@api/user'

const DEFAULT_USER: User = {
  id: '',
  email: '',
  email_verified: false,
  first_name: '',
  last_name: '',
  date_joined: '',
  hierarchy_id: '',
  is_active: false,
  is_reporting_entity_admin: false,
  is_staff: false,
  is_external: false,
  is_onboarded: false,
  position: '',
  private_settings: {
    dataset: '',
    language: 'de',
    onboarding: { materiality: false },
  },
  reporting_entity: '',
  get_reporting_entity_name: '',
  permissions: [],
}

export const useUserStore = defineStore('user', () => {
  const user = ref(DEFAULT_USER)
  const loggedIn = ref(false)
  const ready = ref(false)
  const isLoading = ref(true)
  const showOnboarding = ref(false)

  const { fetchData: fetchReportingEntity, resetState: resetReportingEntities } = useReportingEntitiesStore()

  function ensurePrivateSettings(dataset = '') {
    const currentUser = user.value
    if (!currentUser) return
    const defaultSettings: User['private_settings'] = {
      dataset,
      onboarding: {
        materiality: false,
      },
    }

    if (!currentUser.private_settings) currentUser.private_settings = defaultSettings
    else
      currentUser.private_settings = {
        ...defaultSettings,
        ...currentUser.private_settings,
      }
  }

  async function setUser(newUser: User) {
    loggedIn.value = true

    const defaultReportingEntity = localStorage.getItem('default_reporting_entity')
    let reportingEntity = localStorage.getItem('reporting_entity')

    if (defaultReportingEntity !== newUser.reporting_entity) {
      localStorage.setItem('default_reporting_entity', newUser.reporting_entity)
      localStorage.setItem('reporting_entity', newUser.reporting_entity)
      reportingEntity = newUser.reporting_entity
    }

    if (!reportingEntity) {
      reportingEntity = newUser.reporting_entity
      localStorage.setItem('reporting_entity', reportingEntity)
    }

    user.value = newUser

    await fetchReportingEntity(reportingEntity)
    const { current: currentEntity } = useReportingEntitiesStore()
    ensurePrivateSettings(currentEntity?.datasets[0])

    ready.value = true
  }

  function resetUser() {
    loggedIn.value = false
    ready.value = false
    resetReportingEntities()

    user.value = DEFAULT_USER
  }

  const { email } = tokenStore.value
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const originPathname = window.location.pathname

  // we got a mail, lets see if the user is still logged in
  if (email) {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
      console.debug('user already logged in as', email)
    }
    user.value.email = email
    loggedIn.value = true

    getUser()
      .then(async (profile) => {
        tokenStore.value.email = tokenStore.value.email ?? profile.email
        await setUser(profile)
        isLoading.value = false
      })
      .catch(() => {
        loggedIn.value = false
        handleLogout()
      })

    // we got a magic login token, lets try it!
  } else if (token) {
    handleTokenLogin(token).then(() => (isLoading.value = false))

    // user is not logged in
  } else {
    console.debug('user not logged in')
    isLoading.value = false
    loggedIn.value = false
    handleLogout()
  }

  const nameOrEmail = computed(() => {
    const firstName = user.value.first_name
    const lastName = user.value.last_name

    return firstName && lastName ? `${firstName} ${lastName}` : user.value.email
  })

  const initials = computed(() => {
    if (user.value.is_external) return 'XX'
    const first = user.value.first_name[0] || ''
    const second = user.value.last_name[0] || ''

    return `${first}${second}`.toUpperCase()
  })

  const datasetId = computed(() => {
    const { current: currentEntity } = useReportingEntitiesStore()
    const maybeDataset = user.value.private_settings?.dataset
    const datasetSet = maybeDataset && currentEntity?.datasets.includes(maybeDataset)

    return datasetSet ? maybeDataset : (currentEntity?.datasets[0] ?? 'no-dataset')
  })

  const isAdmin = computed(() => user.value.is_staff || user.value.is_reporting_entity_admin)
  const isStaff = computed(() => user.value.is_staff)

  function handleLogout() {
    resetUser()
    removeToken()

    const nonAuthRoute = isNonAuthRoute(window.location.pathname as string)
    const externalRoute = isExternalRoute(window.location.pathname as string)

    if (!nonAuthRoute && !externalRoute)
      window.location.replace(`/login?origin=${window.location.pathname}${window.location.search}`)
  }

  async function getUser(): Promise<User> {
    try {
      const result = await getProfile()
      if (!result.success) {
        console.debug('failed to fetch user details, logging out', result.error)
        return Promise.reject()
      }
      return result.data
    } catch (err) {
      console.debug('failed to fetch user details, logging out', err)
      return Promise.reject()
    }
  }

  async function handleTokenLogin(token: string) {
    try {
      const result = await login(token)
      if (!result.success) {
        console.debug('failed logging external user, logging out', result.error)
        handleLogout()
        return
      }
      const profile = result.data
      user.value.email = profile.email
      loggedIn.value = true
      const { access, exp } = tokenStore.value
      tokenStore.value = { access, email: profile.email, exp }
      await setUser(profile)

      const assigned = urlParams.get('assigned')
      const url = new URL(`${window.location.origin}${originPathname}`)

      if (assigned) url.searchParams.set('assigned', assigned as string)
      return window.location.replace(url.toString())
    } catch (err) {
      console.debug('failed logging external user, logging out', err)
      handleLogout()
    }
  }

  async function finishOnboarding() {
    if (user.value.is_onboarded) {
      toggleOnboardingModal()
      return
    }
    await updateUserDetails({ is_onboarded: true }, user.value.id)
    user.value.is_onboarded = true
  }

  function toggleOnboardingModal() {
    showOnboarding.value = !showOnboarding.value
  }
  const forceMaterialityOnboarding = ref(false)

  const showMaterialityOnboarding = computed(() => {
    return !user.value.private_settings?.onboarding?.materiality || forceMaterialityOnboarding.value
  })

  async function finishMaterialityOnboarding() {
    if (user.value.private_settings?.onboarding?.materiality) {
      toggleMaterialityOnboarding()
      return
    }
    const settings = user.value.private_settings
    if (settings) {
      // typescript doesn't if it is there
      if (settings.onboarding) settings.onboarding.materiality = true
      else settings.onboarding = { materiality: true }
    }
    user.value.private_settings = settings // refs are shallow
    await updateUserDetails({ private_settings: user.value.private_settings }, user.value.id)
  }

  function toggleMaterialityOnboarding() {
    forceMaterialityOnboarding.value = !forceMaterialityOnboarding.value
  }

  return {
    user,
    nameOrEmail,
    initials,
    datasetId,
    isAdmin,
    isStaff,
    loggedIn,
    setUser,
    resetUser,
    ready,
    isLoading,
    finishOnboarding,
    showOnboarding,
    toggleOnboardingModal,
    showMaterialityOnboarding,
    finishMaterialityOnboarding,
    toggleMaterialityOnboarding,
  }
})
