<script setup lang="ts">
import useLocales from '@/composables/useLocales'

const { LL } = useLocales()

export interface Props {
  label?: string
  big?: boolean
  optional?: boolean
  disabled?: boolean
}
defineProps<Props>()
</script>

<template>
  <label
    class="text-gray-dark dark:text-gray-light cursor-pointer select-none"
    :class="[big ? 'mt-2 mb-1 text-base font-bold' : 'my-1 text-sm', disabled ? 'opacity-60 pointer-events-none' : '']"
  >
    <slot>{{ label }}</slot>
    <span v-if="optional" class="text-sm text-gray-light font-normal"> ({{ LL.optional() }})</span>
  </label>
</template>
b
