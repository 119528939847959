<script setup lang="ts">import { ref as _ref } from 'vue';

import { FormKitIcon } from '@formkit/vue'
import { useUserStore } from '@store/user'
import useLocales from '@composable/useLocales'

const { LL } = useLocales()

const currentSlide = _ref(0)
import Slide1 from '@component/onboarding/Slide1.vue'
import Slide2 from '@component/onboarding/Slide2.vue'
import Slide3 from '@component/onboarding/Slide3.vue'
import Slide4 from '@component/onboarding/Slide4.vue'
import Slide5 from '@component/onboarding/Slide5.vue'

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5]
const userStore = useUserStore()

function finishOnboarding() {
  userStore.finishOnboarding()
}
</script>

<template>
  <div class="w-full h-full flex flex-col justify-between">
    <transition name="slide-fade" mode="out-in">
      <div class="flex-grow" :key="currentSlide">
        <component :is="slides[currentSlide]" />
      </div>
    </transition>
    <div class="flex justify-center items-center min-h-[100px] gap-8 transition-opacity pt-12">
      <FormKitIcon
        icon="circleArrowRightOutline"
        class="w-9 rotate-180"
        :class="[currentSlide <= 0 ? 'opacity-0' : 'transition-opacity opacity-100 delay-500 ease-in cursor-pointer']"
        @click="currentSlide > 0 ? currentSlide-- : (currentSlide = 0)"
      />

      <div class="flex gap-2.5">
        <FormKitIcon
          icon="circle"
          class="w-2 cursor-pointer"
          :class="[currentSlide + 1 === circle ? 'text-white' : 'text-blue-700']"
          v-for="circle in slides.length"
          :key="circle"
          @click="currentSlide = circle - 1"
        />
      </div>

      <FormKitIcon
        v-if="currentSlide !== slides.length - 1"
        icon="circleArrowRightOutline"
        class="w-9 cursor-pointer"
        @click="currentSlide++"
      />
      <FormKitIcon
        v-else
        icon="close"
        class="w-9 cursor-pointer"
        @click="finishOnboarding"
        :title="LL.onboarding.slide5.finishOnboarding()"
      />
    </div>
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
