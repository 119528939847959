import { z } from 'zod'
import type { ReportingStandard } from '@/stores/reporting/standards'

export const materialityProcessPattern = z.object({
  id: z.string(),
  name: z.string(),
  reporting_entity_id: z.string(),
  reporting_standard: z.any(), // TODO: I really cannot be bothered
  reporting_standard_id: z.number(),
  is_locked: z.boolean(),
  created_at: z.string(),
  modified_at: z.string(),
  description_business_model: z.string(),
  description_value_chain: z.string(),
  description_process_involved_persons: z.string(),
})
export const batchModePattern = z.union([z.literal('append'), z.literal('replace'), z.literal('skip')])

export type MaterialityProcess = z.infer<typeof materialityProcessPattern> & {
  reporting_standard: ReportingStandard
}

export type BatchMode = z.infer<typeof batchModePattern>
