<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

import { FormKitIcon } from '@formkit/vue'
import CGOverlayPanel from '@component/CGOverlayPanel.vue'
import GhgpMetricsModal from '@component/QuestionnaireSection/GhgpMetricsModal.vue'
import { useDialog } from 'primevue/usedialog'
import useLocales from '@composable/useLocales'
import type { StaticMetricResponse } from '@api/gri'

export interface Props {
  type: string
  disabled?: boolean
  ghgpData?: StaticMetricResponse[]
  value?: string
  inline?: boolean
  showImport?: boolean
}
const props = defineProps<Props>()

export interface Events {
  (e: 'onSubmit', value: number | string): void
}
const emit = defineEmits<Events>()

const { LL } = useLocales()

const dialog = useDialog()
const inputElement = ref(null)
const importOverlay = ref()

async function showOverlay(event: any) {
  importOverlay.value.show(event)
}
function hideOverlay() {
  importOverlay.value.hide()
}

const showAddImpactDialog = () => {
  hideOverlay()
  dialog.open(GhgpMetricsModal, {
    props: {
      modal: true,
      header: LL.value.report.importData.insertDataMeasure(),
    },
    data: { value: props?.value ?? '', ghgpData: props?.ghgpData ?? [] },
    onClose: async (payload) => {
      if (payload?.data && payload.data.action === 'submit') {
        emit('onSubmit', payload.data.data)
      }
    },
  })
}

onClickOutside(inputElement, () => hideOverlay)

defineExpose({
  showAddImpactDialog,
})
</script>
<template>
  <div ref="inputElement">
    <FormKitIcon
      v-if="!['boolean'].includes(type) && (showImport || type === 'rich-text')"
      icon="download"
      :class="{
        'absolute right-0 top-0.5': !inline,
        'w-6 opacity-100': inline,
        'w-4 opacity-50': type !== 'rich-text',
        'cursor-not-allowed pointer-events-none opacity-50': disabled,
        'cursor-pointer hover:opacity-100': !disabled,
      }"
      @click="showOverlay($event)"
      :title="LL.report.importData.importFromMeasure()"
    />
    <CGOverlayPanel
      ref="importOverlay"
      root-class="bg-black text-white dark:bg-white dark:text-black p-0 rounded-lg"
      content-class="p-0"
    >
      <div class="flex flex-col divide-y divide divide-gray-dark text-base">
        <div class="flex flex-row gap-2 items-center px-3 py-2.5 cursor-pointer" @click="showAddImpactDialog">
          <FormKitIcon icon="dashboard" class="inline w-6 h-6" />
          {{ LL.report.importData.importFromMeasure() }}
        </div>
      </div>
    </CGOverlayPanel>
  </div>
</template>
