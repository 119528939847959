import { ref, type Ref } from 'vue'
import { camelCased } from '@util/types'
import {
  fetchDisclosureDraftBatches,
  createDisclosureDraftBatch,
  acceptDisclosureDraftBatch,
  declineDisclosureDraftBatch,
  cancelDisclosureDraftBatch,
  type DisclosureDraftBatchDTO,
} from '@api/materiality/disclosureDraftBatch'
import type { BatchMode } from '@api/materiality'

export type DisclosureDraftBatch = CamelCase<DisclosureDraftBatchDTO>

const pollingDelay = 1000 // poll every second
let polling: NodeJS.Timeout
let stopPolling = false
let cache: DisclosureDraftBatch | null = null

// global state refs
let loading: Ref<boolean>
let waiting: Ref<boolean>
let ready: Ref<boolean>

const defaultOptions = {
  initImmediately: false,
}

export default function disclosureDraftBatchAdapter(options = defaultOptions) {
  if (loading === undefined) loading = ref(false)
  if (waiting === undefined) waiting = ref(false)
  if (ready === undefined) ready = ref(false)

  const batch = ref<DisclosureDraftBatch | null>(cache)

  function setBatch(newBatch: DisclosureDraftBatch) {
    batch.value = cache = newBatch
    if (newBatch.disclosureDrafts) {
      waiting.value = newBatch.disclosureDrafts.some((d) => d.requestStatus === 'Waiting')
      ready.value = newBatch.disclosureDrafts.some((d) => d.requestStatus === 'Successful')
    }
    stopPolling = !waiting.value
  }

  async function update() {
    loading.value = true

    const result = await fetchDisclosureDraftBatches({ is_open: true })
    if (!result.success) {
      console.error('Disclosure Draft Batch error', result.error)
      stopPolling = true
      cache = null
      return
    }

    // we always assume only few batches, so pagination can (probably) be ignored
    const batches = result.data.results.map(camelCased)
    // probably unneccessary, because the API should filter them already
    const openBatches = batches.filter((b) => b.disclosureDrafts.length > 0)

    if (openBatches.length) {
      // Assuming one open batch exists at a time, but that might change,
      // so let's pick the last entry for now, just in case.
      setBatch(openBatches[openBatches.length - 1])
    } else {
      // no batches, no polling ¯\_(ツ)_/¯
      stopPolling = true
      cache = null
    }

    loading.value = false
  }

  function poll() {
    if (stopPolling) return
    update()
    polling = setTimeout(poll, pollingDelay)
  }

  function reset() {
    batch.value = null
    cache = null
    loading.value = false
    waiting.value = false
    ready.value = false
  }

  async function createBatch(processId: string, reportId: string, mode: BatchMode) {
    if (loading.value || !stopPolling) return

    loading.value = true
    waiting.value = true
    ready.value = false
    stopPolling = false

    const result = await createDisclosureDraftBatch(
      {
        materiality_process_id: processId,
        report_id: reportId,
        mode,
      },
      { processId },
    )

    loading.value = false

    if (!result.success) {
      console.error('failed to create disclosure draft batch', result.error)
      waiting.value = false
      return
    }

    batch.value = cache = camelCased(result.data)
    polling = setTimeout(poll, pollingDelay)
  }

  async function accept(overrides = {} as Record<string, string>) {
    if (!batch.value) return
    await acceptDisclosureDraftBatch(batch.value.id, overrides)
    reset()
  }

  async function decline() {
    if (!batch.value) return
    await declineDisclosureDraftBatch(batch.value.id)
    reset()
  }

  async function cancel() {
    if (!batch.value) return
    await cancelDisclosureDraftBatch(batch.value.id)
    reset()
  }

  function init() {
    if (cache === null) update()
    else setBatch(cache)

    if (polling === undefined && !stopPolling) poll()
  }

  if (options.initImmediately) init()

  return { init, loading, batch, waiting, ready, createBatch, accept, decline, cancel, reset }
}
