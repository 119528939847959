export function formatNumber(value: number, locale?: string, options = {}) {
  const formatter = new Intl.NumberFormat(locale, options)
  return formatter.format(value)
}

/** breaks values down to smaller units
 * To make very small values more readable, this function converts for example
 * 0.002t to 2kg
 */
function unitBreakDown(value: number, units: string[], factor = 1000) {
  let val = value
  let unit = units[0]
  const lastUnit = units.at(-1)

  for (const u of units) {
    unit = u
    if (val >= 1 || unit === lastUnit) break
    else val *= factor
  }

  return {
    value: val,
    unit,
  }
}

/** breaks values up to bigger units
 * To make very large values more readable, this function converts for example
 * 2000t to 2kt
 */
function unitBreakUp(value: number, units: string[], factor = 1000) {
  let val = value
  let unit = units[0]
  const lastUnit = units.at(-1)

  for (const u of units) {
    unit = u
    if (val < factor || unit === lastUnit) break
    else val /= factor
  }

  return {
    value: val,
    unit,
  }
}

export function meaningfulUnit(value: number, unit: string, locale?: string, options = {}) {
  let result = { value, unit }

  switch (unit) {
    case 'ton': // falls through
    case 'metric_ton': {
      if (value >= 1000) {
        const units = ['t', 'kt', 'Mt', 'Gt']
        result = unitBreakUp(value, units)
      } else {
        const units = ['t', 'kg', 'g']
        result = unitBreakDown(value, units)
      }
      break
    }
    case 'megajoule': {
      if (value >= 1000) {
        const units = ['MJ', 'GJ']
        result = unitBreakUp(value, units)
      } else {
        const units = ['MJ', 'kJ', 'J']
        result = unitBreakDown(value, units)
      }
      break
    }
    case 'megaliter': {
      if (value >= 1000) {
        const units = ['Ml', 'Gl']
        result = unitBreakUp(value, units)
      } else {
        const units = ['Ml', 'kl', 'l']
        result = unitBreakDown(value, units)
      }
      break
    }
    case 'kilowatt_hour': {
      if (value >= 1000) {
        const units = ['kWh', 'MWh', 'GWh']
        result = unitBreakUp(value, units)
      } else {
        const units = ['kWh', 'Wh']
        result = unitBreakDown(value, units)
      }
      break
    }
    case 'kilogram': {
      if (value >= 1000) {
        const units = ['kg', 't', 'kt', 'Mt', 'Gt']
        result = unitBreakUp(value, units)
      } else {
        const units = ['kg', 'g']
        result = unitBreakDown(value, units)
      }
      break
    }
    case 'liter': {
      if (value >= 1000) {
        const units = ['l', 'kl', 'Ml', 'Gl']
        result = unitBreakUp(value, units)
      } else {
        const units = ['l', 'ml']
        result = unitBreakDown(value, units)
      }
      break
    }
  }

  // unfortunately, we cannot use Intl unit formatting,
  // because it doesn't support all of the units we need.
  // see: https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
  // for supported units

  const formattedValue = formatNumber(result.value, locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  })

  return `${formattedValue} ${result.unit}`
}

export function toDateString(
  d: string | number | Date,
  locale?: string,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  } as Intl.DateTimeFormatOptions,
) {
  const date = new Date(d)
  return new Intl.DateTimeFormat(locale, options).format(date)
}

// tries to figure out whether it is "foo, bar, baz" or "foo,bar,baz" or "foo bar baz"
// does not support mixed versions like "foo,bar baz, quux"
export function splitted(s: string): string[] {
  s = s.trim().replace(/^,{1,}|,{1,}$/g, '') // remove dangling space and commas
  if (s.length < 3) return [s]
  if (s.indexOf(', ') >= 0) return s.split(', ')
  if (s.indexOf(',') >= 0) return s.split(',')
  if (s.indexOf(' ') >= 0) return s.split(' ')
  return [s]
}

// TODO: this allows every two-letter combination as country code
export function splitCountryCodes(s: any): CountryCode[] {
  if (typeof s !== 'string') return []
  const list = splitted(s).filter((cc) => cc.length === 2)
  return list.map((cc) => cc.toLocaleUpperCase() as CountryCode)
}

export function reformatDate(date: Date): string {
  return (
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')
  )
}
