import { z } from 'zod'
import { createObjectFactoryZod, fetchPaginatedFactoryZod } from '@util/api'
import { apiCreate } from '@api/client'
import { batchModePattern } from '@api/materiality'

const disclosureDraftBatchPattern = z.object({
  id: z.string(),
  author_id: z.string(),
  mode: batchModePattern,
  status: z.string(),
  report_id: z.string(),
  materiality_process_id: z.string(),
  // materiality_process equals materiality_process_id unless specifically
  // expanded via ?expand=materiality_process parameter
  materiality_process: z.string(), // materialityProcessPattern,
  disclosure_drafts: z
    .object({
      id: z.string(),
      reporting_requirement_id: z.number(),
      reporting_requirement: z.any(), // sorry, this is too much work for now
      user_input: z.string().nullish(),
      language: z.string(),
      response: z.string().nullish(),
      request_status: z.enum(['Waiting', 'Successful', 'Failed']),
      user_response: z.enum(['Declined', 'Accepted']).nullish(),
    })
    .array(),
  user_response: z.enum(['open', 'closed']),
})

const createDisclosureDraftBatchPattern = z.object({
  materiality_process_id: z.string(),
  report_id: z.string(),
  mode: batchModePattern,
})

export type DisclosureDraftBatchDTO = z.infer<typeof disclosureDraftBatchPattern>

export type FetchAllArgs = {
  is_open: boolean
  page?: number
  page_size?: number
}

export type CreateDisclosureDraftBatch = z.infer<typeof createDisclosureDraftBatchPattern>

export const fetchDisclosureDraftBatches = fetchPaginatedFactoryZod<DisclosureDraftBatchDTO, FetchAllArgs>(
  disclosureDraftBatchPattern,
)('disclosure-draft-batches')

export const createDisclosureDraftBatch = createObjectFactoryZod<DisclosureDraftBatchDTO, CreateDisclosureDraftBatch>(
  createDisclosureDraftBatchPattern,
)('materiality/materiality-processes/:processId:/create-disclosure-draft-batch')

export async function acceptDisclosureDraftBatch(batchId: string, overrides: any) {
  const url = `disclosure-draft-batches/${batchId}/accept`
  return await apiCreate(url, { overrides })
}

export async function declineDisclosureDraftBatch(batchId: string) {
  const url = `disclosure-draft-batches/${batchId}/decline`
  return await apiCreate(url, {})
}

// TODO: this endpoint does not exist, yet
export async function cancelDisclosureDraftBatch(batchId: string) {
  const url = `disclosure-draft-batches/${batchId}/cancel`
  return await apiCreate(url, {})
}
