<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { FormKitIcon } from '@formkit/vue'

export interface Props {
  text: string
  type?: 'info' | 'warning' | 'error'
  big?: boolean
}
withDefaults(defineProps<Props>(), { type: 'info',big: false, })

const size = computed(() => (__props.big ? 6 : 5))
const iconClass = ref('text-primary bg-primary/10 border-primary/10')
const icon = ref('info')

onMounted(() => {
  if (__props.type === 'warning') {
    iconClass.value = 'text-yellow bg-yellow/10 border-yellow/10'
  } else if (__props.type === 'error') {
    iconClass.value = 'text-red bg-red/10 border-red/10'
    icon.value = 'danger'
  }
})
</script>
<template>
  <div
    class="flex items-center gap-2 p-3 rounded-lg border shadow-sm"
    :class="[iconClass, big ? 'text-sm' : 'text-xs']"
  >
    <FormKitIcon :icon :class="[type === 'info' ? 'rotate-180' : '', `w-${size}`]" />
    <p class="w-11/12">{{ text }}</p>
  </div>
</template>
