<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { createInput } from '@formkit/vue'

import TextField from '@component/forms/TextField.vue'
import CGSelectButton from '@component/CGSelectButton.vue'
import InsertData from '@component/QuestionnaireSection/InsertData.vue'
import { FormKitIcon } from '@formkit/vue'
import LoadingSpinner from '@component/LoadingSpinner.vue'
import RichText_ from '@component/RichText.vue'

import type { FormKitNode } from '@formkit/core'
import useLocales from '@composable/useLocales'
import type { StaticMetricResponse } from '@api/gri'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import {
  extractReferenceData,
  getParsedLastValue,
  isReferenceDataChange,
  getReferenceIndexes,
} from '@util/inputReference'
import { useGghpMetricsStore } from '@store/reporting/ghgpMetrics'

const RichText = createInput(RichText_, {
  props: ['notEditable', 'disabled'],
})

export interface Props {
  type: string
  label?: string
  value: string | number | boolean | null
  fieldProps?: Record<string, string | boolean>
  disabled?: boolean
  classes?: string
  forTable?: boolean
}
const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update', value: string | number | boolean | null): void
  (e: 'node', value: FormKitNode): void
  (e: 'focus'): void
  (e: 'blur'): void
  (e: 'errorReference'): void
}>()

const { LL } = useLocales()
const LLL = computed(() => LL.value.unit)
const { current: reportingEntity } = useReportingEntitiesStore()
const ghgpStore = useGghpMetricsStore()
const { possibleCombinations } = storeToRefs(ghgpStore)

const triStateValue = ref(props.value !== '' ? (props.value as boolean | null) : null)
const triStateOptions = computed(() => [
  { label: LL.value.yes(), value: true },
  { label: LL.value.no(), value: false },
  { label: LL.value.noComment(), value: null },
])

const focussed = ref(false)
const showImport = ref(false)

// ghgp metric values
const referenceComp = ref(null)
const isGhgpReference = computed(
  () =>
    !['rich-text', 'boolean'].includes(props.type) &&
    props.value &&
    (props.value as string).includes('ref.ghgp-metrics'),
)
const referenceKey = ref('')
const isGhgpDataAvailble = computed(
  () =>
    !!ghgpStore.possibleCombinations[referenceKey.value] && ghgpStore.possibleCombinations[referenceKey.value].length,
)
const isGhgpDataloading = ref(false)

const refItems = computed(() => {
  if (
    !['rich-text', 'boolean'].includes(props.type) &&
    props.value &&
    typeof props.value === 'string' &&
    props.value &&
    (props.value as string).includes('ref.ghgp-metrics')
  ) {
    return (props.value as string).split('|')
  }
  return []
})

const parsedReferenceValue = computed(() => getParsedLastValue(refItems.value.join('|'), LLL.value))
const startDate = computed(() => {
  if (!refItems.value.length) return ''
  return (refItems.value.find((item) => item.includes('start_date')) as string)?.split('=')[1]
})

const endDate = computed(() => {
  if (!refItems.value.length) return ''
  return (refItems.value.find((item) => item.includes('end_date')) as string)?.split('=')[1]
})

const datasetIds = computed(() => {
  if (!refItems.value.length) return []
  return (refItems.value.find((item) => item.includes('dataset_ids')) as string)
    ?.split('=')[1]
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .split(',')
})

const reportingEntityId = computed(() => {
  if (!refItems.value.length) return ''
  return (refItems.value.find((item) => item.includes('reference_reporting_entity_id')) as string)?.split('=')[1]
})

const metricScopes = computed(() => {
  if (!refItems.value.length) return ''
  const metrics = (refItems.value.find((item) => item.startsWith('metric')) as string)?.split('=')[1].split('_')
  return metrics
})

const metricKey = ref(metricScopes.value.length > 0 && metricScopes.value[0].replace(/'/g, ''))
const metricType = ref(metricScopes.value.length > 0 && metricScopes.value[1].replace(/'/g, ''))

const refItemsRT = computed(() => getReferenceIndexes(props.value as string, props.type))

const ghgpData = ref<StaticMetricResponse[]>([])

// Rich-Text fields emit an update event right after being initialized with a
// value, which is picked up by the auto-save function. To avoid this, events
// are suppressed if they send the same value
function checkForDuplicate(newValue: string) {
  if (newValue !== props.value) emit('update', newValue)
}

async function addReference() {
  if (refItems.value.length && props.type !== 'rich-text') {
    isGhgpDataloading.value = true
    const body = {
      start_date: startDate.value,
      end_date: endDate.value,
      dataset_ids: datasetIds.value,
      reference_reporting_entity_id: reportingEntityId.value,
    }
    referenceKey.value = JSON.stringify(body)
    await ghgpStore.addMetricCombinations(referenceKey.value)
  }
}

function updateReferenceData() {
  ghgpData.value = possibleCombinations.value[referenceKey.value]
  const metric = ghgpData.value.find((item) => item.metric === metricKey.value) as StaticMetricResponse | undefined
  if (metric) {
    isGhgpDataloading.value = false
    const newRef = isReferenceDataChange(metric, metricType.value as string, parsedReferenceValue.value, refItems.value)
    if (newRef) emit('update', newRef)
  }
}

async function fetchGHGPDataForRichText() {
  if (props.type === 'rich-text' && props.value && refItemsRT.value !== null && refItemsRT.value.startIndexes.length) {
    const copiedValue = props.value as string
    let newRichTextValue = ''
    for (let i = 0; i < refItemsRT.value.startIndexes.length; i++) {
      const codeStr = (props.value as string).substring(
        refItemsRT.value.startIndexes[i],
        refItemsRT.value.endIndexes[i],
      )
      const start = codeStr.indexOf('{{')
      const end = codeStr.indexOf('}}')
      const reference_text = codeStr.substring(start, end + 2)
      const {
        dataset_ids,
        start_date,
        end_date,
        reference_reporting_entity_id,
        metric_key,
        metric_type,
        actualValue,
        splitItems,
      } = extractReferenceData(reference_text, LLL.value)
      try {
        const body = {
          start_date,
          end_date,
          dataset_ids,
          reference_reporting_entity_id: reference_reporting_entity_id ?? (reportingEntity?.id as string),
          metric: metric_key,
        }
        await ghgpStore.fetchMetrics(body)
        ghgpData.value = possibleCombinations.value[JSON.stringify(body)]
        const metric = ghgpData.value.find((item) => item.metric === metric_key) as StaticMetricResponse
        if (metric) {
          const newRef = isReferenceDataChange(metric, metric_type, actualValue, splitItems)
          if (newRef) {
            const lastVal = getParsedLastValue(newRef, LLL.value)
            const newCodeStr = `<code class="bg-gray-btn p-1 rounded-md cursor-pointer before:content-none after:content-none pointer-events-none" title="Imported ref" reference-data="${newRef}">${lastVal}</code>`
            newRichTextValue = copiedValue.replace(codeStr, newCodeStr)
            if (newRichTextValue !== props.value) emit('update', newRichTextValue)
          }
        }
      } catch {
        console.error('error creating ghgp metric request')
      }
    }
  }
}

const onReferenceClick = () => {
  if (isGhgpReference.value && referenceComp.value) {
    ;(referenceComp.value as any)?.showAddImpactDialog()
  }
}
addReference()
fetchGHGPDataForRichText()

watch(
  [isGhgpDataAvailble],
  () => {
    if (isGhgpDataAvailble.value) updateReferenceData()
  },
  { immediate: true },
)
</script>

<template>
  <div class="relative w-full" @mouseover="showImport = true" @mouseleave="showImport = false">
    <div
      v-if="parsedReferenceValue"
      class="w-full p-1.5 rounded-lg overflow-y-ellipsis line-clamp-1"
      :class="{
        'border border-gray-border dark:border-gray-border': !forTable,
        'opacity-50 pointer-events-none': disabled,
      }"
    >
      <span
        class="w-fit mr-4 px-2 py-1 flex flex-row gap-2 items-center bg-gray-btn dark:bg-gray-btn dark:text-black font-medium rounded-lg overflow-y-ellipsis line-clamp-2 cursor-pointer"
        @click="onReferenceClick"
        :title="parsedReferenceValue"
      >
        <FormKitIcon
          v-if="isGhgpReference && !isGhgpDataloading"
          icon="dashboard"
          class="inline w-4 h-4 cursor-pointer"
        />
        <LoadingSpinner v-else-if="isGhgpReference && isGhgpDataloading" class="inline h-4 w-4" />
        <span class="pointer-events-none">{{ parsedReferenceValue }}</span>
      </span>
    </div>
    <!-- When the input is not a reference value -->
    <div v-else>
      <TextField
        v-if="type === 'string'"
        class="w-full"
        :label="label ?? ''"
        :modelValue="(value as string) ?? ''"
        @update:modelValue="emit('update', $event as string)"
        @node="emit('node', $event)"
        v-bind="{ ...fieldProps, disabled }"
      />
      <FormKit
        v-else-if="type === 'textarea'"
        type="textarea"
        :class="`${disabled ? 'w-full pointer-events-none opacity-60' : `w-full`}`"
        v-bind="{ ...fieldProps, disabled }"
        :inner-class="`flex justify-center items-center rounded-lg ${
          fieldProps?.embedded ? 'border-none' : 'bg-white dark:bg-black'
        } ${focussed ? 'border border-blue dark:border-blue' : 'border border-gray-border dark:border-gray-border'}`"
        :input-class="`w-full mr-3 rounded-lg text-black dark:text-white resize-none ${classes} ${
          fieldProps?.embedded ? 'bg-white/0' : 'bg-white dark:bg-black'
        } ${fieldProps?.readonly ? 'cursor-default' : ''} outline-none invalid:bg-red/10`"
        :label="label ?? ''"
        label-class="text-sm text-gray-dark dark:text-gray-light"
        :modelValue="(value as string) ?? ''"
        @update:modelValue="emit('update', $event as string)"
        @node="emit('node', $event)"
        @focus="
          () => {
            focussed = true
            emit('focus')
          }
        "
        @blur="
          () => {
            focussed = false
            emit('blur')
          }
        "
      />
      <TextField
        v-else-if="type === 'number'"
        number
        class="w-full"
        :label="label ?? ''"
        :modelValue="`${value ?? null}`"
        @update:modelValue="
          emit('update', $event.length && !$event.includes('ref.ghgp-metrics') ? Number($event) : null)
        "
        @node="emit('node', $event)"
        v-bind="{ ...fieldProps, disabled }"
      />
      <CGSelectButton
        v-else-if="type === 'boolean'"
        class="min-w-60"
        :modelValue="triStateValue"
        @update:modelValue="emit('update', $event)"
        :invalid="(fieldProps?.invalid ?? false) as boolean"
        :disabled
        :options="triStateOptions"
      />

      <FormKit
        v-else-if="type === 'rich-text'"
        :type="RichText"
        :label="label ?? ''"
        :disabled
        :modelValue="(value as string) ?? ''"
        outer-class="w-full"
        inner
        @update:modelValue="checkForDuplicate($event as string)"
        @node="emit('node', $event)"
      />

      <template v-else-if="type === 'none'">
        <!-- nothing to render here, just making sure, all known cases are handled -->
      </template>

      <p v-else>¿unknown type: {{ type }}?</p>
    </div>

    <InsertData
      ref="referenceComp"
      v-if="!fieldProps?.readonly"
      :type
      :disabled
      :value="parsedReferenceValue ? (props.value as string) : ''"
      :ghgpData
      :showImport
      @on-submit="type === 'rich-text' ? {} : emit('update', $event)"
    />
  </div>
</template>
