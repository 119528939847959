import type { Ref } from 'vue'
import { useStorage } from '@vueuse/core'

export type FeatureFlags = { [key: string]: Record<string, boolean> }
const FEATURE_FLAGS: FeatureFlags = {
  global: {
    showExportInSidebar: false,
    showGlobalHelpIcon: false,
    showOnboarding: false,
    showInvoiceBatches: false,
  },
  dashboard: {
    helpfulErrors: false,
  },
  report: {
    disclosureReviewer: false,
  },
  materiality: {
    enableLocking: false,
    onboarding: false,
    export: false,
  },
  spreadSheet: {
    showCompare: false,
    showMapping: false,
  },
  euTaxonomy: {
    enabled: false,
  },
}

export default function useFeatureFlags() {
  const flagStore: Ref<FeatureFlags> = useStorage('features', FEATURE_FLAGS, localStorage, { mergeDefaults: true })

  return {
    flagStore,
    resetFlagStore: () => (flagStore.value = FEATURE_FLAGS),
  }
}
