import type { TranslationFunctions } from '@i18n/i18n-types'
import type { Ref } from 'vue'
import type { TreeNode } from 'primevue/treenode'

export function linkDataForTasks(LL: Ref<TranslationFunctions>) {
  const linkData: TreeNode[] = [
    {
      label: LL.value.sideBar.tasks(),
      value: 'task',
      icon: 'tickSquare',
      children: [
        { label: LL.value.tasks.board(), value: 'tasks-board' },
        { label: LL.value.tasks.projects.title(0), value: 'tasks-projects' },
      ],
    },
    {
      label: LL.value.sideBar.dashboard(),
      data: 'performance',
      icon: 'dashboard',
      children: [{ label: LL.value.taskModal.environmental(), value: 'performance-environmental' }],
    },
    {
      label: LL.value.sideBar.accounting(),
      value: 'measure',
      icon: 'accounting',
      children: [
        { label: LL.value.taskModal.newActivityTitle(), value: 'measure-activities' },
        { label: LL.value.taskModal.activityLog(), value: 'measure-activity-history' },
        { label: LL.value.taskModal.uploadHistory(), value: 'measure-history-invoices' },
      ],
    },
    {
      label: LL.value.sideBar.improvement(),
      value: 'improve',
      icon: 'leaf',
      children: [{ label: LL.value.taskModal.goals(), value: 'improve-goals' }],
    },
    {
      label: LL.value.sideBar.materiality(),
      value: 'materiality',
      icon: 'dataTree',
      children: [
        { label: LL.value.taskModal.materiality.overview(), value: 'report-materiality-overview' },
        { label: LL.value.taskModal.materiality.stakeholders(), value: 'report-materiality-stakeholders' },
        { label: LL.value.taskModal.materiality.impacts(), value: 'report-materiality-impacts' },
        { label: LL.value.taskModal.materiality.risksOpportunities(), value: 'report-materiality-risks-opportunities' },
      ],
    },
    {
      label: LL.value.sideBar.report(),
      data: 'report',
      icon: 'clipboard',
      children: [{ label: LL.value.sideBar.report(), value: 'report' }],
    },
    {
      label: LL.value.sideBar.supplyChain(),
      value: 'supply',
      icon: 'box',
      children: [
        { label: LL.value.taskModal.supplyChain.suppliers(), value: 'supply-chain-suppliers' },
        { label: LL.value.taskModal.supplyChain.risk(), value: 'supply-chain-risks' },
      ],
    },
    {
      label: LL.value.sideBar.configuration(),
      value: 'configure',
      icon: 'configuration',
      children: [
        { label: LL.value.taskModal.dataset(), value: 'configure-datasets' },
        { label: LL.value.taskModal.reportingEntity(), value: 'configure-reporting-entities' },
        { label: LL.value.taskModal.user(), value: 'configure-users' },
      ],
    },
  ]

  return linkData
}

export function statusForTasks(LL: Ref<TranslationFunctions>, excluding: string) {
  const statusData: LabeledValue<string>[] = [
    { label: LL.value.taskModal.statusText['to-do'](), value: 'to-do' },
    { label: LL.value.taskModal.statusText['in-progress'](), value: 'in-progress' },
    { label: LL.value.taskModal.statusText.review(), value: 'review' },
    { label: LL.value.taskModal.statusText.done(), value: 'done' },
  ]
  return statusData.filter((data) => data.value !== excluding)
}

export function getDueDate(date: string) {
  const differenceInDate = new Date(date).getTime() - new Date().getTime()
  return Math.ceil(differenceInDate / (1000 * 3600 * 24))
}

export function getKwargsForTasks(refPage: string, kwargs: Record<string, string>) {
  let retKwargs = {} as Record<string, string>
  switch (refPage) {
    case 'measure':
      retKwargs = {
        ...(kwargs['category'] ? { category: kwargs['category'] } : {}),
        ...(kwargs['action'] ? { action: kwargs['action'] } : {}),
      }
      break
    default:
      retKwargs = kwargs
  }
  return retKwargs
}

export function getTaskColor(status: string) {
  switch (status) {
    case 'in-progress':
    case 'review':
      return 'bg-yellow-light text-yellow-110'
    case 'done':
      return 'bg-green-light text-green-dark'
    default:
      return 'bg-gray-btn text-gray-dark'
  }
}

const moduleIcons = {
  task: 'tickSquare',
  performance: 'dashboard',
  measure: 'accounting',
  improve: 'leaf',
  materiality: 'dataTree',
  report: 'clipboard',
  supply: 'box',
  configure: 'configuration',
}

export function getTaskIcon(link: string | undefined) {
  const matchedKey = Object.keys(moduleIcons).find((key) => link?.includes(key))
  return matchedKey ? moduleIcons[matchedKey as keyof typeof moduleIcons] : 'tickSquare'
}
