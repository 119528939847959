<script setup lang="ts">
import { computed, inject, type Ref, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import useLocales from '@composable/useLocales'
import useAsDialog from '@/composables/useAsDialog'
import { type StaticMetricResponse } from '@api/gri'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import { useDatasetStore } from '@store/dataset'
import { useFormattedNumber } from '@composable/useFormattedNumber'
import { getStartDate, getEndDate } from '@util/reporting'
import { useGghpMetricsStore } from '@store/reporting/ghgpMetrics'

import CGLabel from '@component/CGLabel.vue'
import CGMultiSelect from '@component/CGMultiSelect.vue'
import DataTable from '@component/DataTable.vue'
import TextField from '@component/forms/TextField.vue'
import FormattedDate from '@component/FormattedDate.vue'
import { FormKitIcon } from '@formkit/vue'
import Skeleton from '@component/Skeleton.vue'
import CGButton from '@component/CGButton.vue'
import { extractReferenceData } from '@/util/inputReference'

export interface ReferenceData {
  value: string
  ghgpData: StaticMetricResponse[]
}

export type METRIC_TYPE = 'label' | 'value' | 'unit'

const { LL } = useLocales()
const LLL = computed(() => LL.value.report.importData)
const LLL_unit = computed(() => LL.value.unit)

const { current: currentDataset, allDatasets } = useDatasetStore()
const ghgpStore = useGghpMetricsStore()
const { possibleCombinations, currentSelection } = storeToRefs(useGghpMetricsStore())
const { current: reportingEntity } = useReportingEntitiesStore()
const entityStore = useReportingEntitiesStore()
const { format } = useFormattedNumber()
const defaultMetricString = ref('')

const { closeDialog, data: reference } = useAsDialog<ReferenceData>()
const dialogRef: Ref<{ close: (value?: any) => void }> | undefined = inject('dialogRef')

const { dataset_ids, start_date, end_date, reference_reporting_entity_id, metric_key, metric_type } =
  extractReferenceData(reference.value.value, LLL_unit.value)

const loading = ref(false)

const selection = ref(
  reference.value.value && dataset_ids.length
    ? dataset_ids
    : currentSelection.value.length
      ? currentSelection.value
      : [currentDataset?.id as string],
)
const ghgpMetrics: Ref<StaticMetricResponse[]> = ref(reference.value.ghgpData.length ? reference.value.ghgpData : [])

const options = computed(() => {
  const subEntities: Set<string> = entityStore.getSubEntityIds()

  return allDatasets
    .reduce((result: any, dataset) => {
      const isCurrentEntity = currentDataset?.reporting_entity === reportingEntity?.id
      const isSubEntity = subEntities.has(dataset.reporting_entity)
      if (isCurrentEntity && isSubEntity) {
        result.push({
          label: dataset.name,
          value: dataset.id,
          parent: dataset.get_reporting_entity_name,
        })
      }
      return result
    }, [])
    .sort((a: any, b: any) => {
      if (a.parent < b.parent) return -1
      if (a.parent > b.parent) return 1
      return 0
    })
})

const startDate = ref(start_date || getStartDate(allDatasets, selection.value))
const endDate = ref(end_date || getEndDate(allDatasets, selection.value))
const selectCustom = ref(false)
const changeDetected = ref(false)

const columns = computed(() => [
  { header: LLL.value.name(), field: 'name' },
  { header: LLL.value.amount(), field: 'amount' },
  { header: LLL.value.unit(), field: 'unit' },
])
const first = ref(0)
const data = computed(() => {
  return ghgpMetrics.value.map((ghgp) => ({
    name: ghgp.label,
    metric: ghgp.metric,
    amount: ghgp.quantity?.value as number,
    unit: ghgp.quantity?.unit as string,
  }))
})

function handleSelect(value: number | string, metric: any, type: METRIC_TYPE) {
  const data = `{{ref.ghgp-metrics|dataset_ids=[${selection.value}]|reference_reporting_entity_id=${reportingEntity?.id}|start_date=${startDate.value}|end_date=${endDate.value}|metric='${metric}_${type}'|last_value=${value}}}`
  dialogRef?.value.close({ action: 'submit', data })
  closeDialog()
}

function handleDelete() {
  dialogRef?.value.close({ action: 'submit', data: null })
  closeDialog()
}

async function handleDatasetSelect() {
  try {
    if (!selection.value.length) return
    loading.value = true
    selectCustom.value = false
    const body = {
      reference_reporting_entity_id: reference_reporting_entity_id || (reportingEntity?.id as string),
      dataset_ids: selection.value,
      start_date: startDate.value,
      end_date: endDate.value,
    }
    await ghgpStore.fetchMetrics(body)
    await ghgpStore.setCurrentSelection(selection.value)
    ghgpMetrics.value = possibleCombinations.value[JSON.stringify(body)] as StaticMetricResponse[]
  } catch {
    ghgpMetrics.value = []
  } finally {
    loading.value = false
  }
}

async function fetchDefaultMetric() {
  try {
    if (!selection.value.length) return
    loading.value = true
    selectCustom.value = false
    const body = {
      reference_reporting_entity_id: reportingEntity?.id as string,
      dataset_ids: selection.value,
      start_date: startDate.value,
      end_date: endDate.value,
    }
    await ghgpStore.fetchMetrics(body)
    defaultMetricString.value = JSON.stringify(body)
    ghgpMetrics.value = possibleCombinations.value[JSON.stringify(body)] as StaticMetricResponse[]
  } catch {
    ghgpMetrics.value = []
  } finally {
    loading.value = false
  }
}

function handleDateChange(date: string, type = false) {
  if (type) startDate.value = date
  else endDate.value = date
}

function validateSelected(type: METRIC_TYPE, key: string) {
  if (
    metric_type === type &&
    metric_key === key &&
    JSON.stringify(selection.value) === JSON.stringify(dataset_ids) &&
    start_date === startDate.value &&
    end_date === endDate.value
  ) {
    return true
  }

  return false
}

watch(selection, (newSelection) => {
  // no need to filter without multiple values
  if (!Array.isArray(newSelection)) return
  if (newSelection.length < 1) {
    ghgpMetrics.value = []
    return
  }
  selection.value = newSelection
  startDate.value = getStartDate(allDatasets, selection.value)
  endDate.value = getEndDate(allDatasets, selection.value)
  changeDetected.value = true
})

if (!reference.value || !reference.value?.ghgpData?.length) {
  if (
    Object.keys(possibleCombinations.value).includes(defaultMetricString.value) &&
    (possibleCombinations.value[defaultMetricString.value] as StaticMetricResponse[]).length
  )
    ghgpMetrics.value = possibleCombinations.value[defaultMetricString.value] as StaticMetricResponse[]
  else fetchDefaultMetric()
}
</script>

<template>
  <div class="w-[625px]">
    <div class="relative flex flex-col justify-start">
      <div class="w-full sticky -top-6 left-0 mb-4 flex flex-row gap-2 z-100 bg-white dark:bg-black-soft">
        <div class="w-1/2 max-w-[300px]">
          <CGLabel>{{ LL.sideBar.dataset() }}</CGLabel>
          <CGMultiSelect
            filter
            v-model="selection"
            :options
            :placeholder="LL.pleaseSelect()"
            required
            @hidden="handleDatasetSelect()"
          >
            <template #option="{ option }">
              <div class="flex flex-col max-w-[550px]">
                <span class="w-[520px] overflow-ellipsis line-clamp-1" :title="option.label">{{ option.label }}</span>
                <span class="w-[520px] overflow-ellipsis line-clamp-1 text-sm text-gray-light mt-1">{{
                  option.parent
                }}</span>
              </div>
            </template>
          </CGMultiSelect>
        </div>
        <div class="w-1/2 max-w-[260px] mr-4">
          <CGLabel>{{ LLL.dateRange() }}</CGLabel>
          <div class="w-full flex flex-row gap-2 items-center">
            <div
              v-if="!selectCustom"
              class="w-full px-4 py-2 flex justify-between gap-2 items-center rounded-lg bg-white border border-gray/30 dark:bg-gray-dark cursor-pointer"
              @click="selectCustom = true"
            >
              <FormKitIcon icon="calendar" class="w-6" />
              <FormattedDate :value="startDate" :value2="endDate" />
              <FormKitIcon class="ml-2 w-6" icon="arrowDown" />
            </div>

            <TextField
              v-if="selectCustom"
              date
              :modelValue="startDate"
              name="start_date"
              @update:modelValue="handleDateChange($event, true)"
            />
            <TextField
              v-if="selectCustom"
              date
              :modelValue="endDate"
              :min="startDate"
              name="end_date"
              @update:modelValue="handleDateChange($event)"
            />
          </div>
        </div>
      </div>
      <div v-if="selectCustom" class="w-full mb-4 flex justify-end">
        <CGButton primary @click="handleDatasetSelect()">{{ LL.update() }}</CGButton>
      </div>
      <div class="w-full max-h-[500px] overflow-y-scroll border-y border-y-gray-btn rounded-lg">
        <DataTable :columns :data :loading :first key="ghgpTable">
          <template #cell="cell">
            <div class="w-full flex flex-col items-center">
              <div
                class="w-full cursor-pointer"
                :class="{
                  'flex flex-col gap-2 bg-blue-100 p-1 rounded-md': validateSelected('label', cell.data.metric),
                }"
                v-if="cell.field === 'name'"
                @click="handleSelect(cell.value, cell.data.metric, 'label')"
              >
                <div v-if="validateSelected('label', cell.data.metric)" class="text-sm text-gray">
                  {{ LLL.selected() }}
                </div>
                {{ cell.value }}
              </div>
              <div
                class="w-full cursor-pointer"
                :class="{
                  'flex flex-col gap-2 bg-blue-100 p-1 rounded-md': validateSelected('value', cell.data.metric),
                }"
                v-else-if="cell.field === 'amount'"
                @click="handleSelect(cell.value, cell.data.metric, 'value')"
              >
                <div v-if="validateSelected('value', cell.data.metric)" class="text-sm text-gray">
                  {{ LLL.selected() }}
                </div>
                {{ format(cell.value) }}
              </div>
              <div
                class="w-full cursor-pointer"
                :class="{
                  'flex flex-col gap-2 bg-blue-100 p-1 rounded-md': validateSelected('unit', cell.data.metric),
                }"
                v-else-if="cell.field === 'unit'"
                @click="handleSelect(cell.value, cell.data.metric, 'unit')"
              >
                <div v-if="validateSelected('unit', cell.data.metric)" class="text-sm text-gray">
                  {{ LLL.selected() }}
                </div>
                {{ LL.unit[cell.value as I18NUnit](cell.data['amount']) }}
              </div>
            </div>
          </template>
          <template #footer v-if="!data.length || loading">
            <div
              v-if="!loading"
              class="w-full h-10 px-2 flex items-center gap-2 text-gray-light border border-b-0 border-x-0 border-t-gray/30"
            >
              {{ LLL.noDatasetSelected() }}
            </div>
            <Skeleton class="mb-2" v-else height="2rem" radius="8px" v-for="i in 5" :key="i" />
          </template>
        </DataTable>
      </div>
      <div
        v-if="!loading && metric_key && !changeDetected"
        class="w-fit mt-4 pb-1 flex flex-row gap-2 border-b-2 border-black-soft dark:border-white-soft font-medium text-base text-black-soft dark:text-white-soft cursor-pointer"
        @click="handleDelete"
      >
        <FormKitIcon icon="deleteIcon" class="inline-block w-5" /> {{ LLL.deleteReference() }}
      </div>
    </div>
  </div>
</template>
